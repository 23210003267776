.login-form {
  .login-form-inputs {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
  }
  .login-links {
    text-align: center;
    margin-top: 20px;
    color: var(--ion-text-color);
  }
}
