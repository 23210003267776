.typeahead-item {
  color: var(--ion-color-dark);
  ion-label {
    padding: 0 16px;
    font-size: 12.5px;
    line-height: 14px;
  }
}
.typeahead {
  width: 100%;
}
.typeahead__control {
  border-bottom: 1px solid var(--ion-color-dark) !important;
}
.typeahead__option {
  &:hover {
    background-color: var(--custom-color-primary-highlight);
  }
}
