.actionability-page {
  padding: 0 16px;
  p {
    margin: 0;
  }
  .section {
    margin: 20px 0;
  }
  .actionability-title {
    margin: 20px 0 10px 0;
    .High {
      color: var(--ion-color-success);
    }
    .Medium {
      color: var(--ion-color-warning);
    }
    .Low {
      color: var(--ion-color-danger);
    }
  }
  .actionability-header {
    color: var(--ion-color-medium);
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 36px;
  }
  .text-title {
    font-weight: bold;
    text-transform: uppercase;
  }
  .references {
    .reference-item {
      display: grid;
      grid-template-columns: 5% 95%;
      margin: 5px 0;
    }
  }

  .frequency-by-cancer-type {
    margin-bottom: 8px;
    display: block;
  }

  .loading-frequency {
    display: flex;
    justify-content: center;
  }

  .chart-section {
    display: grid;
    grid-template-columns: 70% calc(30% - 30px);
    gap: 30px;
    .barchart {
      margin-bottom: 16px;
    }
    .chart-soruce {
      font-size: 12px;
    }
  }
}

.actionability-spinner {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
