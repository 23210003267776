.modal-share-report {
  .sharing-content {
    margin-top: 20px;
  }
  .sharing-message {
    --highlight-height: 1px;
    --highlight-color-focused: var(--ion-color-medium);
    --border-color: var(--ion-color-medium);
    --border-bottom-width: 1px;
    --color-focused: var(--ion-color-medium);
    --inner-padding-end: 0;
  }
  .share-form-box {
    margin: 16px 0;
  }
}
