.modal-terms-page {
  ol:not(.special) {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }
  ol:not(.special) > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  ol:not(.special) > li::before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }

  /* Use same margin bottom for <li>s inside .special lists */
  ol.special > li {
    margin-bottom: 0.6em;
  }
}
