.explore-page-list-item {
  margin-bottom: 64px;
  p {
    margin: 0;
  }
  .epli-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .pmid {
      font-size: 14px;
      color: var(--ion-color-medium);
    }
    .phase {
      color: var(--custom-color-white);
      border-radius: 1.5em;
      padding: 4px 6px;
      margin-left: 6px;
      &.phase-1 {
        background-color: var(--custom-color-phase1);
      }
      &.phase-2 {
        background-color: var(--custom-color-phase2);
      }
      &.phase-3 {
        background-color: var(--custom-color-phase3);
      }
    }
  }
  .epli-title {
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
    cursor: pointer;
  }
  p {
    font-size: 12px;
  }
  .epli-footer {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    display: grid;
    grid-template-columns: calc(90% - 10px) 10%;
    gap: 10px;
    align-items: flex-end;
    .icon {
      cursor: pointer;
      text-align: right;
      ion-icon {
        font-size: 20px;
        &:not(.favorite) {
          color: var(--ion-color-medium);
        }
        &.favorite {
          color: var(--ion-color-primary);
        }
      }
    }
  }
}
