.genomic-variants {
  margin: 20px 0;
  border-radius: 8px;
  background-color: var(--ion-color-light);
  padding: 16px;
  .gv-custom-title {
    margin-bottom: 10px;
  }
  .gv-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .gv-processing {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.gv-actions ion-button {
  width: fit-content;
  margin-top: 10px;
}
.gv-legend {
  margin-top: 10px;
  font-size: 12px;
  color: var(--ion-color-medium);
}
.gv-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gv-add-variant {
  margin-left: auto;
}
