.progress-note {
  position: relative;
  background-color: var(--ion-color-secondary-transparency);
  border-radius: 10px;
  padding: 16px;
  margin: 10px 0 10px 10px;
  .icon {
    position: absolute;
    background-color: var(--ion-color-primary);
    height: 30px;
    width: 30px;
    color: var(--custom-color-white);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
    left: -10px;
  }
}
