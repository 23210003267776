.failed-report {
  background-color: var(--ion-color-light-tint);
  border-radius: 16px;
  padding: 22px 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: fit-content;
  align-items: center;
  margin: 25% auto;
  .icon {
    background: var(--ion-color-danger);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--custom-color-white);
    font-size: 20px;
  }
  .fr-title,
  .fr-subtitle {
    display: block;
  }
}
