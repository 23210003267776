.title-custom {
  color: var(--ion-text-color);
  h1 {
    font-size: 36px;
    font-weight: bold;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    font-weight: bold;
  }
  .title-center {
    text-align: center;
    width: 100%;
  }
}
