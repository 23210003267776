.marker {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 2px;
  background-color: transparent;
  &.blue {
    background-color: var(--ion-color-primary);
  }
}
