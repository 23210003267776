#pdf-input {
  display: none;
}
.upload-button {
  margin-top: 20px;
}
.lab-selection {
  --border-color: var(--ion-color-medium);
  --inner-padding-end: 0;
}
.modal-new-report-wrapper {
  --height: fit-content;
}
.confirm-patient-subtitle {
  color: var(--ion-color-medium);
  text-align: center;
  font-size: 14px;
  margin: 8px 0;
  display: block;
  text-align: center;
}
