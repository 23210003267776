.custom-chip {
  &.warning {
    --background: var(--ion-color-warning);
  }
  &.success {
    --background: var(--ion-color-success);
  }
  &.danger {
    --background: var(--ion-color-danger);
  }
}
