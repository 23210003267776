.button-link {
  --padding-start: 0;
  --padding-end: 0;
  --background-hover: transparent;
  color: var(--ion-color-primary);
  text-transform: initial;
  margin: 0;
  height: fit-content;
  &.no-link {
    text-decoration: none;
  }
  &:not(.no-link) {
    text-decoration: initial;
  }
}
