.patient-report-history {
  width: 100%;
  margin-bottom: 16px;
  .prh-spinner .spinner {
    text-align: center;
  }
  table {
    background-color: var(--custom-color-white);
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    padding: 10px;
    h4 {
      margin: 8px 0;
      font-weight: bold;
      text-align: left;
    }
    th,
    td {
      padding: 8px 24px;
      font-size: 12px;
      &:not(:last-child) {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  thead {
    border-bottom: 1px solid var(--ion-color-light-shade);
  }
  tbody {
    tr {
      border-bottom: 1px solid var(--ion-color-light);
      .marker {
        margin: 0 6px 0 -12px;
      }
      td:last-child {
        text-align: right;
      }
      &.not-read {
        background-color: var(--custom-color-primary-highlight);
      }
    }
  }
}
