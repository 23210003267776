@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0px;
}

.modal-full-size {
  --height: 100%;
  --width: 100%;
  --border-radius: 0;
}

ion-button {
  --box-shadow: 0;
  --border-width: 1px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ion-input[readonly] {
  color: #737373;
}

ion-datetime-button::part(native) {
  width: 100%;
  background: transparent;
  text-align: left;
  padding-left: 14px;
}
ion-datetime {
  --background: white;
  --background-rgb: 255, 241, 242;

  border-radius: 16px;
  border: 1px solid #E0E0E0;
}
ion-popover.select-popover {
  --width: auto;
}



// Form styles
form .required {
  color: #b8182d;
  padding-left: 5px !important;
}

form .label {
  display: flex !important;
}

form ion-select {
  --padding-start: 16px;
  --padding-top: 2px;
  --padding-bottom: 12px;
  --height: 40px;
}

form .error {
  color: #b8182d;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 16px;
}

form .error-field {
  margin-bottom: 24px !important;
}

form .error-field ion-input,
form .error-field ion-select {
  margin-bottom: -6px;
}

form .free-checkbox-input {
  gap: 10px !important;
  margin: 24px 16px 24px 16px !important;
  font-size: 14px !important;
}