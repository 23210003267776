.heading-wrapper {
  background-image: url('../../../../public/assets/dna-reverse.svg'),
    linear-gradient(to left, var(--ion-color-primary), var(--ion-color-secondary));
  background-repeat: no-repeat;
  min-height: 250px;
  padding: 24px 36px;
  background-size: contain;
  background-position: right;

  .line {
    display: flex;
    justify-content: space-between;
  }

  .chips-and-input {
    width: 100%;
  }

  .title {
    h1 {
      color: var(--custom-color-white);
    }
  }

  .searchbar {
    gap: 20px;
    margin-top: 20px;

    ion-item {
      --border-color: var(--custom-color-white);
      --padding-end: 0;
      --background: transparent;
      --color: var(--custom-color-white);
      border-bottom: 1px solid var(--custom-color-white);
      flex: 1;
    }

    ion-input {
      --background: transparent;
      --color: var(--custom-color-white);
      caret-color: var(--custom-color-white);
    }

    .search-item {
      --inner-padding-end: 0;
      flex: 45%;
    }

    .divider {
      border-left: 1px dashed var(--custom-color-white);
    }

    .orderby-item {
      flex-direction: 30%;

      ion-select::part(icon) {
        content: url('../../../../public/assets/arrow-down-white.svg');
        opacity: 1;
        padding: 0 6px;
        mix-blend-mode: color-burn;
      }
    }
  }

  .chips {
    flex-wrap: wrap;
    margin: 20px 0;
    justify-content: flex-start;

    .clear-button {
      text-transform: initial;
    }
  }
}