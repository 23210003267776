.default-card {
  border-radius: 15px;
  background-color: var(--ion-color-light);
  padding: 10px;
  height: fit-content;
  .dc-title {
    font-size: 20px;
    color: var(--ion-color-primary);
    margin-bottom: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .inside-card {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--custom-color-white);
  }
}
