.patients-item {
  background-color: var(--custom-color-white);
  border-bottom: 3px solid var(--ion-color-light);
  height: 64px;
  &.not-read {
    background-color: var(--custom-color-primary-highlight);
  }
  .pi-marker-flex {
    display: flex;
    align-items: baseline;
  }
  td {
    padding: 4px 10px;
    vertical-align: top;
    &:first-child {
      white-space: nowrap;
    }
  }
  small {
    color: var(--ion-color-medium);
  }
}
