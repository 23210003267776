.message-span {
  margin: 4px 16px;
  &.success {
    color: var(--ion-color-success);
  }
  &.warning {
    color: var(--ion-color-warning);
  }
  &.error {
    color: var(--ion-color-danger);
  }
}
