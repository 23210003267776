.explore-wrapper {
  .chips-and-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .explore-page-spinner {
    text-align: center;
    margin: 10px 0;
  }
  .results-summary {
    color: var(--ion-color-primary);
    font-size: 12px;
    display: block;
  }
}
.modal-filter-explore-wrapper {
  --height: fit-content;
}
