.therapies-select {
  --background: var(--ion-color-light);
  .notes-slot {
    background-color: var(--custom-color-white);
    border-radius: 8px;
    margin: 20px 0;
    display: grid;
    grid-template-columns: 35% 65%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
  }
}
.ts-footer {
  background-color: var(--custom-color-white);
}
