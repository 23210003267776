#patient-heading {
  background-image: url('../../../../../public/assets/dna-reverse.svg'),
    linear-gradient(to left, var(--ion-color-primary), var(--ion-color-secondary));
  background-repeat: no-repeat;
  min-height: 300px;
  padding: 24px 36px;
  background-size: contain;
  background-position: right;
  .ph-background {
    display: flex;
    justify-content: space-between;
    .ph-info {
      color: var(--custom-color-white);
      h1 {
        margin: 0;
      }
      .ph-chip {
        background-color: var(--ion-color-secondary);
        width: fit-content;
        font-size: 12px;
        padding: 4px 6px;
        border-radius: 0.5em;
      }
    }
  }
}
